import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import LayoutEN from "../../../composants/layout/en/LayoutEN"
import ListeCartesTitreEtDescription from "../../../composants/navigation/ListeCartesTitreEtDescription"
import Section from "../../../composants/layout/Section"

import { obtenirPage, obtenirPages } from "../../../js/client-es/utils"
import fragmentsImages from "../../../js/client-es/fragments/fragments-images"
import fragmentsPages from "../../../js/client-es/fragments/fragments-pages"

export const requete = graphql`
  {
    pageIndexTutoriels: allPagesCompilationYaml(
      filter: { code: { eq: "indexTutorielsEN" } }
    ) {
      ...FragmentPageYaml
    }
    pagesVoieLactee: allPagesCompilationYaml(
      filter: {
        code: {
          in: [
            "photographierLaVoieLacteeEN"
            "traitementImagesVoieLacteeEN"
            "photographierArcheVoieLacteeEN"
          ]
        }
      }
      sort: { fields: ordre_carte, order: ASC }
    ) {
      ...FragmentCarteLienVersPage
    }
    pagesAuroresBoreales: allPagesCompilationYaml(
      filter: {
        code: {
          in: [
            "comprendreAuroresBorealesEN"
            "guideAuroresBorealesEN"
            "photographierAuroresBorealesEN"
          ]
        }
      }
      sort: { fields: ordre_carte, order: ASC }
    ) {
      ...FragmentCarteLienVersPage
    }
    pagesLogicielsMateriels: allPagesCompilationYaml(
      filter: {
        code: {
          in: ["materielAstrophotographieEN", "logicielsAstrophotographieEN"]
        }
      }
      sort: { fields: ordre_carte, order: ASC }
    ) {
      ...FragmentCarteLienVersPage
    }
    pagesAutresArticles: allPagesCompilationYaml(
      filter: { code: { in: ["previsionsCouvertureNuageuseEN"] } }
      sort: { fields: ordre_carte, order: ASC }
    ) {
      ...FragmentCarteLienVersPage
    }
  }
`

export default function IndexTutoriels() {
  const resultatsRequete = useStaticQuery(requete)
  const pageIndexTutoriels = obtenirPage(resultatsRequete, "pageIndexTutoriels")
  const pagesVoieLactee = obtenirPages(resultatsRequete, "pagesVoieLactee")
  const pagesAuroresBoreales = obtenirPages(
    resultatsRequete,
    "pagesAuroresBoreales"
  )

  const pagesLogicielsMateriels = obtenirPages(
    resultatsRequete,
    "pagesLogicielsMateriels"
  )
  const pagesAutresArticles = obtenirPages(
    resultatsRequete,
    "pagesAutresArticles"
  )

  return (
    <LayoutEN page={pageIndexTutoriels}>
      <Section titre="Milky Way">
        <ListeCartesTitreEtDescription pages={pagesVoieLactee} />
      </Section>
      <Section titre="Northern lights">
        <ListeCartesTitreEtDescription pages={pagesAuroresBoreales} />
      </Section>
      <Section titre="Software and equipments">
        <ListeCartesTitreEtDescription pages={pagesLogicielsMateriels} />
      </Section>
      <Section titre="Other articles">
        <ListeCartesTitreEtDescription pages={pagesAutresArticles} />
      </Section>
    </LayoutEN>
  )
}
